export default {
  content: 'p-2 sm:p-4 pt-2',
  main: 'container mx-auto',
  // nav_background
  nav: 'py-4 text-gray-100 bg-cover bg-no-repeat bg-center shadow-lg',
  // logo: 'font-effect-shadow-multiple font-press-start md:text-3xl',
  logo: 'w-48',
  footer: 'sticky top-0 text-center p-4 bg-gradient-to-r from-green-900 to-green-700 text-gray-100',
  global: 'min-h-screen h-full bg-gradient-to-r from-gray-900 to-gray-700',
}
