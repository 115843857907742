import React, { useContext, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { Link } from 'gatsby-plugin-intl'
import { StaticImage } from 'gatsby-plugin-image'
import styles from '../styles/layout.style'
// import background from '../images/nav_background.png'
import LanguageSwitcher from './LanguageSwitcher'
import { ProfileContext } from '../context/ProfileContext'
import BackgroundImage from 'gatsby-background-image'
// import styled from 'styled-components'

const Layout = ({ children }) => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "nav_background.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const intl = useIntl()
  const { updateLocale } = useContext(ProfileContext)
  // update locale for heroes in profile context
  useEffect(() => {
    updateLocale(intl.locale)
  }, [])
  return (
    <div className={styles.global}>
      <BackgroundImage
        fluid={background.childImageSharp.fluid}
        className={styles.nav}
        style={{
          backgroundPosition: `center bottom 35% !important`,
        }}
        // style={{
        //   backgroundImage: `url(${background})`,
        //   backgroundPosition: `center bottom 35%`,
        // }}
      >
        {/* <nav
        className={styles.nav}
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: `center bottom 35%`,
        }}
      > */}
        <div className="flex sm:justify-between justify-around items-center w-full max-w-screen-md xl:max-w-screen-lg sm:px-8 mx-auto">
          <Link to="/">
            <StaticImage
              className={styles.logo}
              src="../images/nav_title.png"
              alt="logo"
              // alt={intl.formatMessage({ id: 'hero_limit_break' })}
            />
          </Link>
          {/* <span
          className={styles.logo}
          style={{ textShadow: '0.1em 0.1em 0 black' }}
        >
          Guardian Tales
          <br />
          Profile
        </span> */}
          {/* <ul className="flex">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul> */}
          <LanguageSwitcher />
        </div>
        {/* </nav> */}
      </BackgroundImage>
      <main className={styles.main}>{children}</main>
      {/* <div
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded absolute bottom-0 sticky"
        role="alert"
      >
        <strong class="font-bold">Holy smokes!</strong>
        <span class="block sm:inline">Something seriously bad happened.</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg
            class="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div> */}
      <footer className={styles.footer}>
        <Link to="/">
          <p className="text-xs sm:text-sm font-press-start" style={{ textShadow: '2px 2px 0 black' }}>
            © {new Date().getFullYear()} guardiantalesprofile.com
          </p>
        </Link>
        <p className="text-xs pt-2">Graphic contents from Guardian Tales are the property of Kakao Games Corp.</p>
      </footer>
    </div>
  )
}

// const StyledBackgroundImage = styled(BackgroundImage)`
//   &:before,
//   &:after {
//     background-position: center bottom 35%;
//   }
// `

export default Layout
