import React, { Fragment, useContext, useEffect } from 'react'
import { changeLocale, useIntl } from 'gatsby-plugin-intl'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const LanguageSwitcher = () => {
  const intl = useIntl()
  const options = [
    { name: 'English', value: 'en' },
    { name: '中文', value: 'zh' },
  ]

  return (
    <Listbox
      value={intl.locale}
      onChange={(option) => {
        changeLocale(option)
        // sortHeroList(option)
        // setSelected(option)
      }}
      className="ml-auto py-2"
    >
      <div className="relative mt-1">
        <Listbox.Button className="flex flex-row flex-nowrap items-center p-2 cursor-pointer sm:text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
            />
          </svg>
          <span className="block truncate p-2">{options.find((e) => e.value === intl.locale).name}</span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute py-1 mt-1 overflow-hidden text-base bg-white rounded-md shadow-lg max-h-60 sm:text-sm z-40">
            {options.map((option, i) => (
              <Listbox.Option
                key={i}
                className={({ active }) =>
                  `${
                    active ? 'text-yellow-900 bg-yellow-100' : 'text-gray-900'
                  } cursor-default select-none relative py-2 pl-10 pr-4 -ml-2`
                }
                value={option.value}
              >
                {({ selected, active }) => (
                  <>
                    <span className={`${selected ? 'font-bold' : 'font-normal'} block truncate`}>{option.name}</span>
                    {selected ? (
                      <span
                        className={`${active ? 'text-yellow-600' : 'text-yellow-600'}
                              absolute inset-y-0 left-0 flex items-center pl-4`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default LanguageSwitcher
